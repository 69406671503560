<script setup lang="ts">
const { t } = useT();
const loginGuard = useLoginGuard();
const { open } = useAppModals();

const props = defineProps<{
	enabled?: boolean;
	image: string;
	finishedAt?: string;
}>();

const emit = defineEmits<{ (event: "click"): void }>();
const handleClick = () => {
	emit("click");
};

const { durationLeft } = useCountdown({
	timestamp: (props?.finishedAt as string) || "",
	formatToken: "DD[d] HH[h] mm[m]",
	isOptimized: true
});

const handleOpenMoneyBox = (event: MouseEvent) => {
	loginGuard({
		success() {
			event?.stopPropagation();
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail() {
			open("LazyOModalSignup");
		}
	});
};
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 584,
			height: 760,
			src: image,
			format: 'webp',
			alt: 'Leprechaun promo',
			loading: 'lazy'
		}"
		width="292px"
		height="380px"
		@click="handleClick"
	>
		<template #top>
			<AText class="countdown" :modifiers="['uppercase', 'center']" as="div">
				{{ t("Ends in") }}&nbsp;<b>{{ durationLeft }}</b>
			</AText>
		</template>
		<template #bottom>
			<AAnimationShake class="pot">
				<NuxtImg
					src="/nuxt-img/seasons/leprechaun/pot.png"
					format="webp"
					width="186"
					height="191"
					alt="pot"
					loading="lazy"
				/>
			</AAnimationShake>

			<AButton variant="primary" size="xl" class="btn" @click.capture="handleOpenMoneyBox">
				<AText variant="turin" :modifiers="['medium']"> {{ t("Make Purchase") }} </AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.countdown {
	position: absolute;
	top: 6px;
}

.pot {
	position: absolute;
	right: -1px;
	z-index: 2;

	img {
		width: 109px;
		height: 112px;
	}
}

.btn {
	width: 100%;
	position: relative;
	z-index: 2;
}
</style>
